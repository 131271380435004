import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Spinner, Form } from "reactstrap";
import axios from "axios";
import moment from "moment";
import qs from "query-string";
import CountrySelection from "../components/International/CountrySelection";
import ItemDetails from "../components/International/ItemDetails";
import Notes from "../components/International/Notes";
import Rates from "../components/International/Rates";
import AddressDetail from "../components/International/AddressDetail";
import QuotePaymentUI from "../components/QuickQoute/QuotePaymentUI";
import Calender from "../components/International/Calender";
import ItemDeclaration from "../components/International/ItemDeclaration";
import ConfirmBook from "../components/International/ConfirmBook";
import ItemInvoice from "../components/International/ItemInvoice";
import ItemPayementUi from "../components/International/InternationalPayement";
import CommercialInvoice from "../components/International/CommercialInvoice";
import IncompleteTransaction from "../components/International/IncompleteInternational";
import { useDispatch, useSelector } from "react-redux";
import postalAwareCountries from "../config/postalAwareCountries.json";
import { getFedexRates, getDhlRates } from "../store/actions/intlActions";
import { v4 as uuidv4 } from "uuid";
import countriesStates from "../config/countriesStates.json";

import firebase from "../config/firebase";
import { getCounters } from "../store/actions/localActions";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
function International() {
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry, "selectedCountry");
  const { country } = useSelector((state) => state.countries);
  let [activeTab, setActiveTab] = useState(1);
  const [bool, setBool] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [itemLoader, setItemLoader] = useState(false);
  const [packageLoader, setPackageLoader] = useState(false);
  const [reference, setReference] = useState("");
  const [hide, setHide] = useState(true);
  console.log(hide, "hide>>>>>>>>>>>>>>>>>>>>");
  const [firstPage, setFirstPage] = useState({
    fromCountry: "",
    toCountry: "",
    fromState: "",
    toState: "",
    fromCity: "",
    toCity: "",
    fromCode: "",
    toCode: "",
  });
  console.log(firstPage, "firstPage");
  let { fedexRates, dhlRates } = useSelector((state) => state.intl);
  let { counters } = useSelector((state) => state.local);

  const [insuranceData, setInsuranceData] = useState({
    insurance: false,
    insuranceValue: "",
  });

  const insuranceOnChange = (name, val) => {
    if (name == "insurance" && val == false) {
      setInsuranceData({
        insurance: false,
        insuranceValue: "",
      });
    } else {
      setInsuranceData({ ...insuranceData, [name]: val });
    }
  };

  const [ratesData, setRatesData] = useState({
    id: "",
    rate: "",
  });

  const ratesOnChange = (val) => {
    setRatesData(val);
  };

  const [secondPage, setSecondPage] = useState([
    {
      length: "",
      width: "",
      height: "",
      weight: "",
    },
  ]);
  const handleRemoveClick = (index) => {
    const list = [...secondPage];
    list.splice(index, 1);
    setSecondPage(list);
  };
  // handle click event of the Add button

  const handleAddClick = () => {
    setSecondPage([
      ...secondPage,
      {
        length: "",
        width: "",
        height: "",
        weight: "",
      },
    ]);
  };

  const handleOnDimensionCardClick = (obj) => {
    let tempItems = [...secondPage];
    if (obj?.title == "Custom") {
      // setSecondPage([
      //   ...secondPage,
      //   {
      //     length: "",
      //     width: "",
      //     height: "",
      //     weight: "",
      //   },
      // ]);
      tempItems[secondPage.length - 1] = {
        length: "",
        width: "",
        height: "",
        weight: "",
      };
      setSecondPage(tempItems);
    } else if (obj?.title == "Medicine") {
      history.push("/contactus");
    } else {
      // setSecondPage([
      //   ...secondPage,
      //   {
      //     length: obj?.length,
      //     width: obj?.width,
      //     height: obj?.height,
      //     weight: "",
      //   },
      // ]);

      tempItems[secondPage.length - 1] = {
        length: obj?.length,
        width: obj?.width,
        height: obj?.height,
        weight: "",
      };
      setSecondPage(tempItems);
    }
  };

  const handleSecondPage = (idx, key, val) => {
    let newArr = [...secondPage];

    newArr[idx][key] = val;

    setSecondPage(newArr);
  };

  const declarationItemChange = (idx, key, val) => {
    let newArr = [...declarationItems];

    newArr[idx][key] = val;

    setDeclarationItems(newArr);
  };

  const history = useHistory();

  const [secondPageLoader, setSecondPageLoader] = useState(false);
  const [thirdPage, setThirdPage] = useState([]);
  const [fourthPage, setFourthPage] = useState([]);
  const [fifthPage, setFifthPage] = useState([]);
  const [sixthPage, setSixthPage] = useState([]);
  const [seventhPage, setSeventhPage] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [firstCompleted, setFirstCompleted] = useState(false);
  const [secondCompleted, setSecondCompleted] = useState(false);
  const [thirdCompleted, setThirdCompleted] = useState(false);
  const [fourthCompleted, setFourthCompleted] = useState(false);
  const [deliveryType, setDeliveryType] = useState("residential");
  const deliveryTypeChange = (val) => {
    setDeliveryType(val);
  };

  const [packageType, setPackageType] = useState("non-document");
  const [intlDeclaration, setIntlDeclaration] = useState({
    type: "personal",
    file: "",
  });

  const packageTypeChange = (value) => {
    if (value == "document") {
      setSecondPage([
        {
          length: "40",
          width: "35",
          height: "2",
          description: "",
          weight: "",
        },
      ]);
      setPackageType(value);
    } else {
      setSecondPage([
        {
          length: "",
          width: "",
          height: "",
          description: "",
          weight: "",
        },
      ]);
      setPackageType(value);
    }
  };

  const [paymentData, setPaymentData] = useState({
    redirectUri: "",
    redirectParams: {
      PAY_REQUEST_ID: "",
      CHECKSUM: "",
    },
  });
  // Clear Data Section

  const clearItemDetails = () => {
    setSecondPage([
      {
        length: "",
        width: "",
        height: "",
        description: "",
        weight: "",
      },
    ]);
    setPackageType("non-document");
    setInsuranceData({
      insurance: false,
      insuranceValue: "",
    });
  };

  const getCountryState = (countryCode, stateCode) => {
    //     if(selectedCountry==='PT'){
    // return ;
    //     }else{
    let obj = countriesStates.find((state) => state.name == countryCode);
    if (obj && obj.states && obj.states.length > 0) {
      let stateName = obj.states.find((state) => state.CODE == stateCode);
      if (stateName) {
        return stateName.STATE;
      } else {
        return;
      }
    } else {
      return;
    }
    // }
  };

  // let firstIntlPage = JSON.parse(localStorage.getItem('intl_first_page'));

  let firstIntlPage = JSON.parse(
    localStorage.getItem("intl_country_selection")
  );
  console.log(firstIntlPage?.toState, "firstIntlPage>>>>>>>>>>>>>>>>>>>>>");
  const insuranceIntlData = JSON.parse(
    localStorage.getItem("intl_insurance_data")
  );

  let secondIntlPage = JSON.parse(localStorage.getItem("intl_second_page"));
  let thirdIntlPage = JSON.parse(localStorage.getItem("intl_third_page"));
  let fourthIntlPage = JSON.parse(localStorage.getItem("intl_fourth_page"));
  let fifthIntlPage = JSON.parse(localStorage.getItem("intl_fifth_page"));
  let sixthIntlPage = JSON.parse(localStorage.getItem("intl_sixth_page"));

  let seventhIntlPage = JSON.parse(localStorage.getItem("intl_seventh_page"));

  let eighthIntlPage = JSON.parse(localStorage.getItem("intl_eighth_page"));
  let payment = JSON.parse(localStorage.getItem("intl_payment"));
  let commercialPage = JSON.parse(localStorage.getItem("intl_commercial_page"));
  // let intl_declaration = localStorage.getItem('intl_declaration');
  // const handleFirstPage = obj => {
  // 	setFirstPage(prevState => [...prevState, obj]);
  // };
  const [contactDetails, setContactDetails] = useState({
    email: "",
    phone: "",
  });

  const contactDetailsChange = (name, val) => {
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };

  const handleFirstPage = (name, val) => {
    console.log(name, val, "name and obj");
    if (name == "fromCountry") {
      setFirstPage((prevState) => ({
        ...prevState,
        [name]: val,
        fromState: "",
        fromCity: "",
        fromCode: "",
      }));
    } else if (name == "toCountry") {
      setFirstPage((prevState) => ({
        ...prevState,
        [name]: val,
        toState: "",
        toCity: "",
        toCode: "",
      }));
    } else {
      setFirstPage((prevState) => ({
        ...prevState,
        [name]: val,
      }));
    }
  };

  // const handleSecondPage = obj => {
  // 	setSecondPage(prevState => [...prevState, obj]);
  // };
  const handleThirdPage = (obj) => {
    setThirdPage((prevState) => [...prevState, obj]);
  };
  const handleFourthPage = (obj) => {
    setFourthPage((prevState) => [...prevState, obj]);
  };
  const handleFifthPage = (obj) => {
    setFifthPage((prevState) => [...prevState, obj]);
  };
  const handleSixthPage = (obj) => {
    setSixthPage((prevState) => [...prevState, obj]);
  };
  const [declarationItems, setDeclarationItems] = useState([
    {
      country: "",
      quantity: "",
      description: "",
      type: "",
      unitValue: "",
      totalValue: "",
    },
  ]);

  const [reason, setReason] = useState("Personal Use");

  const handleRemoveClickDeclaration = (index) => {
    const list = [...declarationItems];
    list.splice(index, 1);
    setDeclarationItems(list);
  };
  const handleAddClickDeclaration = () => {
    setDeclarationItems([
      ...declarationItems,
      {
        country: "",
        quantity: "",
        description: "",
        type: "",
        unitValue: "",
        totalValue: "",
      },
    ]);
  };

  const handleSeventhPage = (obj) => {
    setSeventhPage((prevState) => [...prevState, obj]);
  };
  const handleSubmit1 = () => {
    setActiveTab(1);
  };
  const handleSubmit2 = () => {
    if (firstCompleted) {
      setActiveTab(2);
    }
  };
  const handleSubmit3 = () => {
    if (secondCompleted) {
      setActiveTab(3);
    }
  };
  const handleSubmit4 = () => {
    if (thirdCompleted) {
      setActiveTab(4);
    }
  };
  const [loader, setLoader] = useState(true);

  // International Data

  const [intlFirstPage, setintlFirstPage] = useState({
    fromCountry: "",
    toCountry: "",
    import: "false",
  });

  const [intlSecondPage, setintlSecondPage] = useState({
    totalWeight: "",
    volumetricWeight: "",
    itemDetails: [{ weight: "0", height: "0", length: "0", width: "0" }],
  });
  const [intlThirdPage, setintlThirdPage] = useState({ rate: 0 });
  const [intlFourthPage, setintlFourthPage] = useState({
    date: "",
    text: "",
  });
  const [intlFifthPage, setintlFifthPage] = useState({
    sendingName: "",
    idNubmer: "",
    sendingSurName: "",
    sendingContact: "",
    sendingComplex: "",
    sendingCity: "",
    sendingAddress2: "",
    sendingAddress1: "",
    sendingSuburb: "",
    sendingPostal: "",
    ReceivingSurName: "",
    ReceivingName: "",
    ReceivingContact: "",
    ReceivingAddress1: "",
    ReceivingAddress2: "",
    ReceivingComplex: "",
    ReceivingCity: "",
    ReceivingSuburb: "",
    ReceivingPostal: "",
  });
  const [intlSixthPage, setintlSixthPage] = useState({
    totalItems: [
      {
        country: "",
        quantity: 0,
        description: "",
        type: "",
        unitValue: 0,
        totalValue: 0,
      },
    ],
  });
  const [intlSeventhPage, setintlSeventhPage] = useState({
    billingEmail: "",
    billingContactNo: "",
    billingName: "",
    billingAddress1: "",
    billingAddress2: "",
    billingComplexNo: "",
    billingSuburb: "",
    billingCountry: "",
    billingPostalCode: "",
    billingVAT: "",
  });
  const [paymentPageIn, setPaymentPageIn] = useState({
    paymentRef: { REFERENCE: "" },
    redirectParams: { CHECKSUM: "", PAY_REQUEST_ID: "" },
    redirectUri: "",
  });
  const [intlEighthPage, setintlEighthPage] = useState({ url: "" });
  const [intlCommercialPage, setintlCommercialPage] = useState({ url: "" });

  const clearStorage = () => {
    localStorage.setItem("intl_first_page", JSON.stringify(intlFirstPage));

    localStorage.setItem("intl_second_page", JSON.stringify(intlSecondPage));

    localStorage.setItem("intl_third_page", JSON.stringify(intlThirdPage));

    localStorage.setItem("intl_fourth_page", JSON.stringify(intlFourthPage));

    localStorage.setItem("intl_fifth_page", JSON.stringify(intlFifthPage));

    localStorage.setItem("intl_sixth_page", JSON.stringify(intlSixthPage));

    localStorage.setItem("intl_seventh_page", JSON.stringify(intlSeventhPage));

    localStorage.setItem("intl_eighth_page", JSON.stringify(intlEighthPage));

    localStorage.setItem("intl_payment", JSON.stringify(paymentPageIn));

    localStorage.setItem(
      "intl_commercial_page",
      JSON.stringify(intlCommercialPage)
    );
  };

  const checkPostalAware = (val) => {
    // let obj = postalAwareCountries.find(country => country.CODE == val);
    let obj = countriesStates.find(
      (country) => country.name.toUpperCase() == val.toUpperCase()
    );
    if (obj) {
      return true;
    } else {
      return false;
    }
  };

  const checkTotalWeight = () => {
    let totalWeight = 0;
    secondPage.map((il) => {
      if (il.weight != "") {
        totalWeight += Number(il.weight);
      }
    });
    if (totalWeight != 0) {
      return totalWeight.toFixed(1);
    } else {
      return totalWeight;
    }
  };
  const declarationOnChange = (type, val) => {
    if (type == "personal") {
      setIntlDeclaration({
        type: "personal",
        file: "",
      });
    } else if (type == "commercial") {
      setIntlDeclaration({ ...intlDeclaration, type: "commercial" });
    } else {
      setIntlDeclaration({ ...intlDeclaration, file: val });
    }
  };

  const checkVolumetricWeight = () => {
    let totalVol = 0;
    secondPage.map((vol) => {
      totalVol += (vol.length * vol.width * vol.height) / 5000;
    });
    if (totalVol >= 0.1) {
      return totalVol.toFixed(1);
    } else if (totalVol > 0 && totalVol < 0.1) {
      return totalVol.toFixed(3);
    } else {
      return totalVol;
    }
  };

  const checkLineVolumetricWeight = () => {
    let check = false;
    secondPage.forEach((vol) => {
      let voluWeight = (vol.length * vol.width * vol.height) / 5000;
      if (voluWeight > 68) {
        check = true;
      }
    });

    return check;
  };

  const dataURLtoFile = (dataurl, filename) => {
    if (dataurl != "" && dataurl != null) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return "";
    }
  };

  //Usage example:
  let intlFile = localStorage.getItem("intl_declaration");

  const getDeclarationFile = () => {
    if (intlFile) {
      return {
        uploadInvoice: true,
        file: dataURLtoFile(intlFile, "commercial_invoice"),
      };
    } else {
      return { uploadInvoice: false };
    }
  };

  const checkDeclarationItems = () => {
    let check = true;
    declarationItems.forEach((itm) => {
      if (itm.country == "") {
        check = false;
      }
    });
    return check;
  };
  useEffect(async () => {
    const ref = qs.parse(window.location.search);
    if (Object.keys(ref).length) {
      setLoader(true);
      setReference(ref.REFERENCE);
      await axios
        .post(`${process.env.REACT_APP_DEVELOPMENT}/payment/status`, {
          paymentRef: payment.paymentRef,
        })
        .then(async (res) => {
          if (res.data.success) {
            if (res.data.data.TRANSACTION_STATUS == "1") {
              // await clearStorage();
              setBool(true);
              setLoader(false);
              setActiveTab("9");
            } else {
              clearStorage();
              setLoader(false);
              setActiveTab("1");
            }
          } else {
            clearStorage();
            setLoader(false);
            setActiveTab("1");
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  }, [window.location.search]);
  const getFile = () => {};

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (counters && counters.length === 0) {
      const countersCollection =
        selectedCountry === "PT"
          ? process.env.REACT_APP_COUNTERS_PT
          : process.env.REACT_APP_COUNTERS;
      console.log(countersCollection, "counters collection>>>>>>>>>>>>>>>>>>");
      dispatch(getCounters(countersCollection));
    }
  }, [counters, selectedCountry, dispatch, selectedCountry]);

  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setInitialLoad(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      scroller.scrollTo("selectedCourierSection", {
        smooth: true,
        offset: -85,
      });
    }
  }, [activeTab]);
  const checkGirth = () => {
    let check = false;
    secondIntlPage?.itemDetails.forEach((item) => {
      let girth =
        Number(item.length) + 2 * Number(item.width) + 2 * Number(item.height);

      if (girth > 330) {
        check = true;
      }
    });
    return check;
  };
  const [isSendingPortugalQuote, setIsSendingPortugalQuote] = useState(false);
  //Portugal and Southafrica active tab
  useEffect(() => {
    setActiveTab(2);
    // if (selectedCountry == "PT") {
    //   setActiveTab(2);
    // } else {
    //   setActiveTab(1);
    // }
  }, [selectedCountry]);

  const submitPortugalQuote = async (obj) => {
    try {
      setIsSendingPortugalQuote(true);
      let insuranceDetails = JSON.parse(
        localStorage.getItem("intl_insurance_data")
      );
      let mainItemDetails = JSON.parse(
        localStorage.getItem("intl_second_page")
      );

      const { data } = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/send-quote-confirmation-email`,
        {
          addressDetails: obj,
          contactDetails,
          insuranceDetails,
          ...mainItemDetails,
        }
      );

      setIsSendingPortugalQuote(false);
      history.push("/international-pt-quote");
    } catch (error) {
      setIsSendingPortugalQuote(false);
      alert(
        error?.response?.data?.error ||
          error?.response?.data?.message ||
          "Unable to sent quote!"
      );
    }
  };

  const submitIntlNext = (firstPageData) => {
    // let firstPageData = JSON.parse(
    //   localStorage.getItem("intl_country_selection")
    // );
    let intlInsurance = JSON.parse(localStorage.getItem("intl_insurance_data"));
    let obj = JSON.parse(localStorage.getItem("intl_second_page"));
    console.log("checking obj..", obj);
    console.log("first page", firstPage);
    let requestedLineITems = [];
    let residential = false;
    if (deliveryType == "residential") {
      residential = true;
    }

    let pkgLineItems = [];

    let packages = [];

    obj.itemDetails.forEach((itm, idx) => {
      if (idx == 0) {
        pkgLineItems.push({
          declaredValue: {
            amount:
              intlInsurance.insuranceValue != 0
                ? intlInsurance.insuranceValue
                : 0,
            currency: selectedCountry === "PT" ? "EU" : "ZAR",
          },
          weight: {
            units: "KG",
            value: Number(itm.weight),
          },
          dimensions: {
            length: Number(itm.length),
            width: Number(itm.width),
            height: Number(itm.height),
            units: "CM",
          },
        });
      } else {
        pkgLineItems.push({
          weight: {
            units: "KG",
            value: Number(itm.weight),
          },
          dimensions: {
            length: Number(itm.length),
            width: Number(itm.width),
            height: Number(itm.height),
            units: "CM",
          },
        });
      }

      packages.push({
        weight: Number(itm.weight),
        dimensions: {
          length: Number(itm.length),
          width: Number(itm.width),
          height: Number(itm.height),
        },
      });
    });

    let fedexPayload = {
      accountNumber: {
        value: "690918900",
      },
      requestedShipment: {
        shipper: {
          address: {
            streetLines: ["", ""],
            city: firstPage.fromCity,
            stateOrProvinceCode: firstPage.fromState,

            postalCode: firstPage.fromCode,
            countryCode: firstPage.fromCountry,
            residential: residential,
          },
        },
        recipient: {
          address: {
            streetLines: ["", ""],
            city: firstPage.toCity,
            stateOrProvinceCode: firstPage.toState,
            postalCode: firstPage.toCode,
            countryCode: firstPage.toCountry,
          },
        },
        pickupType: "DROPOFF_AT_FEDEX_LOCATION",
        serviceType: "",
        preferredCurrency: "USD",
        rateRequestType: ["PREFERRED", "ACCOUNT"],
        documentShipment: false,
        totalPackageCount: packages.length,
        totalWeight: Number(obj.totalWeight),
        packagingType: "YOUR_PACKAGING",
        requestedPackageLineItems: pkgLineItems,
      },
    };

    let dhlPayload = {
      requestBody: {
        customerDetails: {
          shipperDetails: {
            postalCode: firstPage.fromCode,
            cityName: firstPage.fromCity,
            countryCode: firstPage.fromCountry,
          },
          receiverDetails: {
            postalCode: firstPage.toCode,
            cityName: firstPage.toCity,
            countryCode: firstPage.toCountry,
          },
        },
        accounts: [
          {
            typeCode: "shipper",
            number: "305995557",
          },
        ],
        productCode: "P",
        valueAddedServices: [
          {
            serviceCode: "II",
            value: Number(intlInsurance.insuranceValue),
            currency: selectedCountry === "PT" ? "EU" : "ZAR",
          },
        ],
        payerCountryCode: firstPage.fromCountry,
        plannedShippingDateAndTime: new Date(),
        unitOfMeasurement: "metric",
        isCustomsDeclarable: true,
        nextBusinessDay: true,
        packages: packages,
      },
    };

    if (packageType == "document") {
      fedexPayload.documentShipment = true;
    }

    setSecondPageLoader(true);
    let doc_id = uuidv4();

    dispatch(
      getFedexRates(
        fedexPayload,
        setHide,
        doc_id,
        {
          totWeight: Number(obj.totalWeight),
          volWeight: Number(obj.volumetricWeight),
        },
        process.env.REACT_APP_DEVELOPMENT,

        (fedexData) => {
          if (Object.keys(fedexData).length === 0) {
            setHide(false);
          }
          dispatch(
            getDhlRates(
              dhlPayload,
              doc_id,
              {
                totWeight: Number(obj.totalWeight),
                volWeight: Number(obj.volumetricWeight),
              },
              (dhlData) => {
                setSecondPageLoader(false);
                setSecondCompleted(true);
                setActiveTab("3");

                let quoteId = 100;
                if (counters.length > 0 && counters[0].quote_counter) {
                  quoteId = counters[0].quote_counter + 1;
                }

                if (
                  Object.keys(fedexData).length > 0 ||
                  Object.keys(dhlData).length > 0
                ) {
                  let quoteTableData = {
                    QuoteId: "qte-" + quoteId,

                    QuoteDate: moment().toISOString(),
                    QuoteType:
                      firstPage.fromCountry == "ZA" &&
                      firstPage.toCountry != "ZA"
                        ? "export"
                        : firstPage.fromCountry != "ZA" &&
                          firstPage.toCountry == "ZA"
                        ? "import"
                        : "3rdparty",
                    ShipperCountry: firstPage?.fromCountry,
                    ShipperProvince: firstPage?.fromState
                      ? getCountryState(
                          firstPage?.fromCountry,
                          firstPage?.fromState
                        )
                      : "",
                    ShipperPostalCode: firstPage?.fromCode,
                    ConsigneeCountry: firstPage?.toCountry,
                    ConsigneeProvince: firstPage?.toState
                      ? getCountryState(
                          firstPage?.toCountry,
                          firstPage?.toState
                        )
                      : "",
                    ConsigneePostalCode: firstPage?.toCode,
                    ItemDetails: {
                      TotalWeight: obj.totalWeight,
                      TotalVolWeight: obj.volumetricWeight,
                    },
                    QuotedPrice: {
                      Fedex: {
                        BaseCurrency: {
                          Currency: selectedCountry === "PT" ? "EU" : "ZAR",
                          UIPriority: fedexData.priority
                            ? fedexData.priority.rate
                            : "",
                          UIEconomy: fedexData.economy
                            ? fedexData.economy.rate
                            : "",
                          COGSPriority: fedexData.apiPriority
                            ? fedexData.apiPriority.rate
                            : "",
                          COGSEconomy: fedexData.apiEconomy
                            ? fedexData.apiEconomy.rate
                            : "",
                        },
                        PreferredCurrency: {
                          Currency: "USD",
                          UIPriority: fedexData.uiPreferredPriority
                            ? fedexData.uiPreferredPriority.rate
                            : "",
                          UIEconomy: fedexData.uiPreferredEconomy
                            ? fedexData.uiPreferredEconomy.rate
                            : "",
                          COGSPriority: fedexData.apiPreferredPriority
                            ? fedexData.apiPreferredPriority.rate
                            : "",
                          COGSEconomy: fedexData.apiPreferredEconomy
                            ? fedexData.apiPreferredEconomy.rate
                            : "",
                        },
                      },
                      DHL: {
                        BaseCurrency: {
                          Currency: selectedCountry === "PT" ? "EU" : "ZAR",
                          UIWorldExpress: dhlData.economy
                            ? dhlData.economy.rate
                            : "",
                          COGSWorldExpress: dhlData.apiEconomy
                            ? dhlData.apiEconomy.rate
                            : "",
                        },
                      },
                    },
                    Currency: selectedCountry === "PT" ? "EU" : "ZAR",
                  };

                  console.log("quoteTableData", quoteTableData);
                  let collection =
                    selectedCountry === "PT"
                      ? process.env.REACT_APP_QUOTE_TABLE_PT
                      : process.env.REACT_APP_QUOTE_TABLE;
                  console.log(collection, "selected collection>>>>>>>>>>>");
                  console.log("Fedex quote data", quoteTableData, collection);
                  firebase
                    .firestore()
                    .collection(`${collection}`)
                    .add(quoteTableData);
                }
              }
            )
          );
        }
      )
    );
  };

  return (
    <div className="banner_carousal">
      <Container fluid>
        {loader ? (
          <div className="d-flex justify-content-center align-items-center py-5">
            <Spinner size="lg" />
          </div>
        ) : (
          <>
            {/* <Row
              className={`justify-content-center mt-2 ${
                activeTab == 9 || activeTab == 10 ? "d-none" : ""
              }`}
            >
              <Col lg="12" align="center">
                <div className="d-flex main_stepper ">
                  <div
                    onClick={handleSubmit1}
                    className={`${
                      activeTab == 1 ? "active" : "inactive"
                    } span__text `}
                  >
                    <span>1.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Origin & Destination
                    </p>
                  </div>
                  <div
                    onClick={handleSubmit2}
                    className={`${
                      activeTab == 2 ? "active" : "inactive"
                    } span__text `}
                  >
                    <span>2.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Shipment Details
                    </p>
                  </div>
                  <div
                    onClick={handleSubmit3}
                    className={`${
                      activeTab == 3 ? "active" : "inactive"
                    } span__text `}
                  >
                    <span> 3.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Quote Estimates
                    </p>
                  </div>
                  <div
                    onClick={handleSubmit4}
                    className={`${
                      activeTab == 4 ||
                      activeTab == 5 ||
                      activeTab == 6 ||
                      activeTab == 7
                        ? "active"
                        : "inactive"
                    } span__text`}
                  >
                    <span>4.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Confirm Booking
                    </p>
                  </div>
                </div>
              </Col>

              
            </Row> */}
            <Row className="justify-content-center " autoComplete="off">
              <Col lg="12" align="center">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (
                      firstPage.fromCountry == "" ||
                      firstPage.toCountry == "" ||
                      firstPage.fromCity == "" ||
                      firstPage.toCity == "" ||
                      firstPage.fromCode == "" ||
                      firstPage.toCode == ""
                    ) {
                      alert("Please select all fields");
                    } else {
                      let obj = {
                        ...firstPage,
                        deliveryType: deliveryType,
                      };
                      if (
                        firstPage.fromState == "" ||
                        firstPage.toState == ""
                      ) {
                        let check = false;

                        if (
                          firstPage.fromState == "" &&
                          checkPostalAware(firstPage.fromCountry)
                        ) {
                          check = true;
                        }
                        if (
                          firstPage.toState == "" &&
                          checkPostalAware(firstPage.toCountry)
                        ) {
                          check = true;
                        }
                        if (check == true) {
                          alert("Select all fields");
                        } else {
                          localStorage.setItem(
                            "intl_country_selection",
                            JSON.stringify(obj)
                          );
                          setFirstCompleted(true);
                          if (selectedCountry == "PT") {
                            // setActiveTab("1");
                            submitPortugalQuote(obj);
                          } else {
                            // setActiveTab("2");
                            submitIntlNext(obj);
                          }
                        }
                      } else {
                        localStorage.setItem(
                          "intl_country_selection",
                          JSON.stringify(obj)
                        );
                        setFirstCompleted(true);
                        if (selectedCountry == "PT") {
                          // setActiveTab("1");
                          submitPortugalQuote(obj);
                        } else {
                          // setActiveTab("2");
                          submitIntlNext(obj);
                        }
                      }
                    }
                  }}
                >
                  <div
                    className={`${
                      activeTab == "1"
                        ? "d-block main_card country-step d-flex justify-content-center align-items-center"
                        : "d-none"
                    }`}
                    id="step1"
                  >
                    <CountrySelection
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      value={firstPage}
                      onChange={handleFirstPage}
                      deliveryType={deliveryType}
                      deliveryTypeChange={deliveryTypeChange}
                      contactDetails={contactDetails}
                      contactDetailsChange={contactDetailsChange}
                    />
                    <Button
                      type="submit"
                      color="secondary"
                      size="lg"
                      className={`step1-btn mr-5 portugal__step1
                      `}
                      // className={`step1-btn mr-5 ${
                      //   selectedCountry == "PT" && "portugal__step1"
                      // }`}
                      disabled={isSendingPortugalQuote || secondPageLoader}
                    >
                      {isSendingPortugalQuote || secondPageLoader ? (
                        <Spinner />
                      ) : (
                        <i className="fas fa-arrow-right"></i>
                      )}
                    </Button>
                  </div>
                </Form>
              </Col>

              <Col lg="12" align="center">
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    console.log("NEXT");
                    //                     if (country === 'PT') {
                    // try{
                    //   setPackageLoader(true);
                    //                       const obj = {
                    //                         ...firstPage,
                    //                         deliveryType: deliveryType,
                    //                         insuranceData,
                    //                         itemDetails: secondPage,
                    //                         packageType,
                    //                         totalWeight: 0,
                    //                         volumetricWeight: 0,
                    //                       };
                    //                       console.log(obj,"obj");
                    //                       let totalWeight = checkTotalWeight();
                    //                       let totalLength = 0;
                    //                     let totalWidth = 0;
                    //                     let totalHeight = 0;
                    //                     let totalVolumetricWeight = checkVolumetricWeight();
                    //                     obj.totalWeight = totalWeight;
                    //                     obj.volumetricWeight = totalVolumetricWeight;
                    //                     let requestedLineITems = [];
                    //                     let residential = false;
                    //                     let firstPageData = JSON.parse(
                    //                       localStorage.getItem("intl_country_selection")
                    //                     );
                    //                     let intlInsurance = JSON.parse(
                    //                       localStorage.getItem("intl_insurance_data")
                    //                     );
                    //                     if (firstPageData.deliveryType == "residential") {
                    //                       residential = true;
                    //                     }

                    //                     let pkgLineItems = [];

                    //                     let packages = [];
                    // console.log(packages,"packages");
                    //                     obj.itemDetails.forEach((itm, idx) => {
                    //                       if (idx == 0) {
                    //                         pkgLineItems.push({
                    //                           declaredValue: {
                    //                             amount:
                    //                               intlInsurance.insuranceValue != 0
                    //                                 ? intlInsurance.insuranceValue
                    //                                 : 0,
                    //                             currency: "PTE",
                    //                           },
                    //                           weight: {
                    //                             units: "KG",
                    //                             value: Number(itm.weight),
                    //                           },
                    //                           dimensions: {
                    //                             length: Number(itm.length),
                    //                             width: Number(itm.width),
                    //                             height: Number(itm.height),
                    //                             units: "CM",
                    //                           },
                    //                         });
                    //                       } else {
                    //                         pkgLineItems.push({
                    //                           weight: {
                    //                             units: "KG",
                    //                             value: Number(itm.weight),
                    //                           },
                    //                           dimensions: {
                    //                             length: Number(itm.length),
                    //                             width: Number(itm.width),
                    //                             height: Number(itm.height),
                    //                             units: "CM",
                    //                           },
                    //                         });
                    //                       }

                    //                       packages.push({
                    //                         weight: Number(itm.weight),
                    //                         dimensions: {
                    //                           length: Number(itm.length),
                    //                           width: Number(itm.width),
                    //                           height: Number(itm.height),
                    //                         },
                    //                       });
                    //                     });

                    //                     let quoteId = 100;
                    //                                 if (
                    //                                   counters.length > 0 &&
                    //                                   counters[0].quote_counter
                    //                                 ) {
                    //                                   quoteId = counters[0].quote_counter + 1;
                    //                                 }
                    //                                   let quoteTableData = {
                    //                                     ...obj,
                    //                                     QuoteDate: moment().toISOString(),
                    //                                     QuoteType:
                    //                                       firstIntlPage.fromCountry == "PTE" &&
                    //                                       firstIntlPage.toCountry != "PTE"
                    //                                         ? "export"
                    //                                         : firstIntlPage.fromCountry != "PTE" &&
                    //                                           firstIntlPage.toCountry == "PTE"
                    //                                         ? "import"
                    //                                         : "3rdparty",
                    //                                     // ShipperCountry: firstIntlPage.fromCountry,
                    //                                     // ShipperProvince: firstIntlPage.fromState
                    //                                     //   ? getCountryState(
                    //                                     //       firstIntlPage.fromCountry,
                    //                                     //       firstIntlPage.fromState
                    //                                     //     )
                    //                                     //   : "",
                    //                                     // ShipperPostalCode: firstIntlPage.fromCode,
                    //                                     // ConsigneeCountry: firstIntlPage.toCountry,
                    //                                     // ConsigneeProvince: firstIntlPage.toState
                    //                                     //   ? getCountryState(
                    //                                     //       firstIntlPage.toCountry,
                    //                                     //       firstIntlPage.toState
                    //                                     //     )
                    //                                     //   : "",
                    //                                     // ConsigneePostalCode: firstIntlPage.toCode,
                    //                                     // ItemDetails: {
                    //                                     //   TotalWeight: totalWeight,
                    //                                     //   TotalVolWeight: totalVolumetricWeight,
                    //                                     // },
                    //                                     // QuotedPrice: {
                    //                                     //   Fedex: {
                    //                                     //     BaseCurrency: {
                    //                                     //       Currency: "PTE",
                    //                                     //       UIPriority: fedexData.priority
                    //                                     //         ? fedexData.priority.rate
                    //                                     //         : "",
                    //                                     //       UIEconomy: fedexData.economy
                    //                                     //         ? fedexData.economy.rate
                    //                                     //         : "",
                    //                                     //       COGSPriority: fedexData.apiPriority
                    //                                     //         ? fedexData.apiPriority.rate
                    //                                     //         : "",
                    //                                     //       COGSEconomy: fedexData.apiEconomy
                    //                                     //         ? fedexData.apiEconomy.rate
                    //                                     //         : "",
                    //                                     //     },
                    //                                     //     PreferredCurrency: {
                    //                                     //       Currency: "USD",
                    //                                     //       UIPriority:
                    //                                     //         fedexData.uiPreferredPriority
                    //                                     //           ? fedexData.uiPreferredPriority
                    //                                     //               .rate
                    //                                     //           : "",
                    //                                     //       UIEconomy:
                    //                                     //         fedexData.uiPreferredEconomy
                    //                                     //           ? fedexData.uiPreferredEconomy
                    //                                     //               .rate
                    //                                     //           : "",
                    //                                     //       COGSPriority:
                    //                                     //         fedexData.apiPreferredPriority
                    //                                     //           ? fedexData.apiPreferredPriority
                    //                                     //               .rate
                    //                                     //           : "",
                    //                                     //       COGSEconomy:
                    //                                     //         fedexData.apiPreferredEconomy
                    //                                     //           ? fedexData.apiPreferredEconomy
                    //                                     //               .rate
                    //                                     //           : "",
                    //                                     //     },
                    //                                     //   },
                    //                                     //   DHL: {
                    //                                     //     BaseCurrency: {
                    //                                     //       Currency: "PTE",
                    //                                     //       UIWorldExpress: dhlData.economy
                    //                                     //         ? dhlData.economy.rate
                    //                                     //         : "",
                    //                                     //       COGSWorldExpress: dhlData.apiEconomy
                    //                                     //         ? dhlData.apiEconomy.rate
                    //                                     //         : "",
                    //                                     //     },
                    //                                     //   },
                    //                                     // },
                    //                                     Currency: "PTE",
                    //                                   };

                    //                                 console.log(quoteTableData,"quoteTableDataPortugal>>>>>>>>")
                    //                                 const { data } = await axios.post(
                    //                                   `${process.env.REACT_APP_DEVELOPMENT}/package-details`,
                    //                                   quoteTableData
                    //                                 );
                    //                                 console.log(data,"data");
                    //                                 setFirstPage({
                    //                                   fromCountry: "",
                    //                                   toCountry: "",
                    //                                   fromState: "",
                    //                                   toState: "",
                    //                                   fromCity: "",
                    //                                   toCity: "",
                    //                                   fromCode: "",
                    //                                   toCode: "",
                    //                                 });

                    //   setSecondPage([
                    //     {
                    //       length: "",
                    //       width: "",
                    //       height: "",
                    //       weight: "",
                    //     },
                    //   ]);
                    //  setInsuranceData({
                    //     insurance: false,
                    //     insuranceValue: "",
                    //   });
                    //   clearStorage();
                    //                                 // if(data.response.status===200){
                    //                                 // setPackageLoader(false);
                    //       //                           clearStates();
                    //       // setIsSubmitting(false);
                    //       history.push("/international/thankyou-portugal");
                    //                                 // }
                    //                               }catch(error){
                    //                                 setPackageLoader(false);
                    //                                 console.log(error.message,"Error in package detail api>>>>>>>");
                    //                               }
                    //                                 }
                    //                     else{

                    const obj = {
                      itemDetails: secondPage,
                      packageType,
                      totalWeight: 0,
                      volumetricWeight: 0,
                    };

                    let totalWeight = checkTotalWeight();

                    let totalLength = 0;
                    let totalWidth = 0;
                    let totalHeight = 0;
                    let totalVolumetricWeight = checkVolumetricWeight();

                    obj.totalWeight = totalWeight;
                    obj.volumetricWeight = totalVolumetricWeight;

                    localStorage.setItem(
                      "intl_second_page",
                      JSON.stringify(obj)
                    );
                    localStorage.setItem(
                      "intl_insurance_data",
                      JSON.stringify(insuranceData)
                    );
                    setActiveTab(1);
                    // if (selectedCountry == "PT") {
                    //   setActiveTab(1);
                    // } else {
                    //   let firstPageData = JSON.parse(
                    //     localStorage.getItem("intl_country_selection")
                    //   );
                    //   let intlInsurance = JSON.parse(
                    //     localStorage.getItem("intl_insurance_data")
                    //   );

                    //   let requestedLineITems = [];
                    //   let residential = false;
                    //   if (firstPageData.deliveryType == "residential") {
                    //     residential = true;
                    //   }

                    //   let pkgLineItems = [];

                    //   let packages = [];

                    //   obj.itemDetails.forEach((itm, idx) => {
                    //     if (idx == 0) {
                    //       pkgLineItems.push({
                    //         declaredValue: {
                    //           amount:
                    //             intlInsurance.insuranceValue != 0
                    //               ? intlInsurance.insuranceValue
                    //               : 0,
                    //           currency: selectedCountry === "PT" ? "EU" : "ZAR",
                    //         },
                    //         weight: {
                    //           units: "KG",
                    //           value: Number(itm.weight),
                    //         },
                    //         dimensions: {
                    //           length: Number(itm.length),
                    //           width: Number(itm.width),
                    //           height: Number(itm.height),
                    //           units: "CM",
                    //         },
                    //       });
                    //     } else {
                    //       pkgLineItems.push({
                    //         weight: {
                    //           units: "KG",
                    //           value: Number(itm.weight),
                    //         },
                    //         dimensions: {
                    //           length: Number(itm.length),
                    //           width: Number(itm.width),
                    //           height: Number(itm.height),
                    //           units: "CM",
                    //         },
                    //       });
                    //     }

                    //     packages.push({
                    //       weight: Number(itm.weight),
                    //       dimensions: {
                    //         length: Number(itm.length),
                    //         width: Number(itm.width),
                    //         height: Number(itm.height),
                    //       },
                    //     });
                    //   });

                    //   let fedexPayload = {
                    //     accountNumber: {
                    //       value: "690918900",
                    //     },
                    //     requestedShipment: {
                    //       shipper: {
                    //         address: {
                    //           streetLines: ["", ""],
                    //           city: firstPageData.fromCity,
                    //           stateOrProvinceCode: firstPageData.fromState,

                    //           postalCode: firstPageData.fromCode,
                    //           countryCode: firstPageData.fromCountry,
                    //           residential: residential,
                    //         },
                    //       },
                    //       recipient: {
                    //         address: {
                    //           streetLines: ["", ""],
                    //           city: firstPageData.toCity,
                    //           stateOrProvinceCode: firstPageData.toState,
                    //           postalCode: firstPageData.toCode,
                    //           countryCode: firstPageData.toCountry,
                    //         },
                    //       },
                    //       pickupType: "DROPOFF_AT_FEDEX_LOCATION",
                    //       serviceType: "",
                    //       preferredCurrency: "USD",
                    //       rateRequestType: ["PREFERRED", "ACCOUNT"],
                    //       documentShipment: false,
                    //       totalPackageCount: packages.length,
                    //       totalWeight: Number(obj.totalWeight),
                    //       packagingType: "YOUR_PACKAGING",
                    //       requestedPackageLineItems: pkgLineItems,
                    //     },
                    //   };

                    //   let dhlPayload = {
                    //     requestBody: {
                    //       customerDetails: {
                    //         shipperDetails: {
                    //           postalCode: firstPageData.fromCode,
                    //           cityName: firstPageData.fromCity,
                    //           countryCode: firstPageData.fromCountry,
                    //         },
                    //         receiverDetails: {
                    //           postalCode: firstPageData.toCode,
                    //           cityName: firstPageData.toCity,
                    //           countryCode: firstPageData.toCountry,
                    //         },
                    //       },
                    //       accounts: [
                    //         {
                    //           typeCode: "shipper",
                    //           number: "305995557",
                    //         },
                    //       ],
                    //       productCode: "P",
                    //       valueAddedServices: [
                    //         {
                    //           serviceCode: "II",
                    //           value: Number(intlInsurance.insuranceValue),
                    //           currency: selectedCountry === "PT" ? "EU" : "ZAR",
                    //         },
                    //       ],
                    //       payerCountryCode: firstPageData.fromCountry,
                    //       plannedShippingDateAndTime: new Date(),
                    //       unitOfMeasurement: "metric",
                    //       isCustomsDeclarable: true,
                    //       nextBusinessDay: true,
                    //       packages: packages,
                    //     },
                    //   };

                    //   if (packageType == "document") {
                    //     fedexPayload.documentShipment = true;
                    //   }

                    //   setSecondPageLoader(true);
                    //   let doc_id = uuidv4();

                    //   dispatch(
                    //     getFedexRates(
                    //       fedexPayload,
                    //       setHide,
                    //       doc_id,
                    //       {
                    //         totWeight: Number(totalWeight),
                    //         volWeight: Number(totalVolumetricWeight),
                    //       },
                    //       process.env.REACT_APP_DEVELOPMENT,

                    //       (fedexData) => {
                    //         if (Object.keys(fedexData).length === 0) {
                    //           setHide(false);
                    //         }
                    //         dispatch(
                    //           getDhlRates(
                    //             dhlPayload,
                    //             doc_id,
                    //             {
                    //               totWeight: Number(totalWeight),
                    //               volWeight: Number(totalVolumetricWeight),
                    //             },
                    //             (dhlData) => {
                    //               setSecondPageLoader(false);
                    //               setSecondCompleted(true);
                    //               setActiveTab("3");

                    //               let quoteId = 100;
                    //               if (
                    //                 counters.length > 0 &&
                    //                 counters[0].quote_counter
                    //               ) {
                    //                 quoteId = counters[0].quote_counter + 1;
                    //               }

                    //               if (
                    //                 Object.keys(fedexData).length > 0 ||
                    //                 Object.keys(dhlData).length > 0
                    //               ) {
                    //                 let quoteTableData = {
                    //                   QuoteId: "qte-" + quoteId,

                    //                   QuoteDate: moment().toISOString(),
                    //                   QuoteType:
                    //                     firstIntlPage.fromCountry == "ZA" &&
                    //                     firstIntlPage.toCountry != "ZA"
                    //                       ? "export"
                    //                       : firstIntlPage.fromCountry != "ZA" &&
                    //                         firstIntlPage.toCountry == "ZA"
                    //                       ? "import"
                    //                       : "3rdparty",
                    //                   ShipperCountry:
                    //                     firstIntlPage?.fromCountry,
                    //                   ShipperProvince: firstIntlPage?.fromState
                    //                     ? getCountryState(
                    //                         firstIntlPage?.fromCountry,
                    //                         firstIntlPage?.fromState
                    //                       )
                    //                     : "",
                    //                   ShipperPostalCode:
                    //                     firstIntlPage?.fromCode,
                    //                   ConsigneeCountry:
                    //                     firstIntlPage?.toCountry,
                    //                   ConsigneeProvince: firstIntlPage?.toState
                    //                     ? getCountryState(
                    //                         firstIntlPage?.toCountry,
                    //                         firstIntlPage?.toState
                    //                       )
                    //                     : "",
                    //                   ConsigneePostalCode:
                    //                     firstIntlPage?.toCode,
                    //                   ItemDetails: {
                    //                     TotalWeight: totalWeight,
                    //                     TotalVolWeight: totalVolumetricWeight,
                    //                   },
                    //                   QuotedPrice: {
                    //                     Fedex: {
                    //                       BaseCurrency: {
                    //                         Currency:
                    //                           selectedCountry === "PT"
                    //                             ? "EU"
                    //                             : "ZAR",
                    //                         UIPriority: fedexData.priority
                    //                           ? fedexData.priority.rate
                    //                           : "",
                    //                         UIEconomy: fedexData.economy
                    //                           ? fedexData.economy.rate
                    //                           : "",
                    //                         COGSPriority: fedexData.apiPriority
                    //                           ? fedexData.apiPriority.rate
                    //                           : "",
                    //                         COGSEconomy: fedexData.apiEconomy
                    //                           ? fedexData.apiEconomy.rate
                    //                           : "",
                    //                       },
                    //                       PreferredCurrency: {
                    //                         Currency: "USD",
                    //                         UIPriority:
                    //                           fedexData.uiPreferredPriority
                    //                             ? fedexData.uiPreferredPriority
                    //                                 .rate
                    //                             : "",
                    //                         UIEconomy:
                    //                           fedexData.uiPreferredEconomy
                    //                             ? fedexData.uiPreferredEconomy
                    //                                 .rate
                    //                             : "",
                    //                         COGSPriority:
                    //                           fedexData.apiPreferredPriority
                    //                             ? fedexData.apiPreferredPriority
                    //                                 .rate
                    //                             : "",
                    //                         COGSEconomy:
                    //                           fedexData.apiPreferredEconomy
                    //                             ? fedexData.apiPreferredEconomy
                    //                                 .rate
                    //                             : "",
                    //                       },
                    //                     },
                    //                     DHL: {
                    //                       BaseCurrency: {
                    //                         Currency:
                    //                           selectedCountry === "PT"
                    //                             ? "EU"
                    //                             : "ZAR",
                    //                         UIWorldExpress: dhlData.economy
                    //                           ? dhlData.economy.rate
                    //                           : "",
                    //                         COGSWorldExpress: dhlData.apiEconomy
                    //                           ? dhlData.apiEconomy.rate
                    //                           : "",
                    //                       },
                    //                     },
                    //                   },
                    //                   Currency:
                    //                     selectedCountry === "PT" ? "EU" : "ZAR",
                    //                 };
                    //                 let collection =
                    //                   selectedCountry === "PT"
                    //                     ? process.env.REACT_APP_QUOTE_TABLE_PT
                    //                     : process.env.REACT_APP_QUOTE_TABLE;
                    //                 console.log(
                    //                   collection,
                    //                   "selected collection>>>>>>>>>>>"
                    //                 );
                    //                 console.log(
                    //                   "Fedex quote data",
                    //                   quoteTableData,
                    //                   collection
                    //                 );
                    //                 firebase
                    //                   .firestore()
                    //                   .collection(`${collection}`)
                    //                   .add(quoteTableData);
                    //               }
                    //             }
                    //           )
                    //         );
                    //       }
                    //     )
                    //   );
                    // }
                    // }
                  }}
                >
                  <div
                    className={`${
                      activeTab == 2
                        ? "d-block main_card country-step d-flex justify-content-center align-items-center"
                        : "d-none"
                    }`}
                    id="step2"
                  >
                    <ItemDetails
                      value={secondPage}
                      checkTotalWeight={checkTotalWeight}
                      checkVolumetricWeight={checkVolumetricWeight}
                      onChange={handleSecondPage}
                      handleAddClick={handleAddClick}
                      handleRemoveClick={handleRemoveClick}
                      handleOnDimensionCardClick={handleOnDimensionCardClick}
                      packageTypeChange={packageTypeChange}
                      packageType={packageType}
                      insuranceChange={insuranceOnChange}
                      insuranceData={insuranceData}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <Button
                      color="secondary"
                      type="submit"
                      disabled={secondPageLoader}
                      // onClick={async () => {
                      //   const obj = Object.assign({}, ...secondPage);
                      //   if (obj.itemDetails) {
                      //     console.log(obj.itemDetails);
                      //     for (let i = 0; i < obj.itemDetails.length; i++) {
                      //       if (
                      //         obj.itemDetails[i].weight &&
                      //         obj.itemDetails[i].weight > 0.5 &&
                      //         obj.itemDetails[i].weight &&
                      //         obj.itemDetails[i].height > 0.5 &&
                      //         obj.itemDetails[i].weight &&
                      //         obj.itemDetails[i].length > 0.5 &&
                      //         obj.itemDetails[i].weight &&
                      //         obj.itemDetails[i].weight > 0.5
                      //       ) {
                      //         return;
                      //       } else {
                      //         setItemLoader(true);
                      //         break;
                      //       }
                      //     }
                      //     if (!itemLoader) {
                      //       let totalWeight = 0;
                      //       let totalweightArray = obj.itemDetails.map((id) => {
                      //         totalWeight = totalWeight + Number(id.weight);
                      //       });
                      //       let volumetricWeight = 0;
                      //       let volumtericweightArray = obj.itemDetails.map(
                      //         (id) => {
                      //           volumetricWeight =
                      //             volumetricWeight +
                      //             (Number(id.length) *
                      //               Number(id.width) *
                      //               Number(id.height)) /
                      //               5000;
                      //         }
                      //       );
                      //       obj.totalWeight = totalWeight;
                      //       obj.volumetricWeight =
                      //         Number(volumetricWeight).toFixed(2);
                      //       localStorage.setItem(
                      //         "intl_second_page",
                      //         JSON.stringify(obj)
                      //       );
                      //       let firstPageData = JSON.parse(
                      //         localStorage.getItem("intl_first_page")
                      //       );
                      //       setSecondPageLoader(true);
                      //       let requestData = {
                      //         toCountry: firstPageData.toCountry,
                      //         weight: JSON.stringify(obj.totalWeight),
                      //       };
                      //       const response = await axios.post(
                      //         "https://ship-my-stuff-live.herokuapp.com/international",
                      //         requestData
                      //       );
                      //       await setQueryData(response.data.data);
                      //       setSecondPageLoader(false);
                      //       setActiveTab("3");
                      //     } else {
                      //       alert("Please Input Minimum value");
                      //     }
                      //   } else {
                      //     alert("Please Add some details");
                      //   }
                      // }}
                      className={`step1-btn  mr-md-5 mr-4 `}
                      size="lg"
                    >
                      {secondPageLoader ? (
                        <Spinner />
                      ) : (
                        <i className="fas fa-arrow-right"></i>
                      )}
                    </Button>
                    {/* {selectedCountry != "PT" && ( */}
                    {/* <Button
                      color="secondary"
                      className="prev_btn ml-lg-5 ml-4"
                      onClick={() => {
                        clearItemDetails();
                        handleSubmit1();
                      }}
                      disabled={secondPageLoader}
                    >
                      <i className="fas fa-arrow-left " />
                    </Button> */}
                    {/* )} */}
                  </div>
                </Form>
              </Col>

              <Col lg="12" align="center">
                <div
                  // className={`${
                  //   activeTab == "3"
                  //     ? "d-block main_card rates-step d-flex justify-content-center align-items-center"
                  //     : "d-none"
                  // }`}
                  className={`${
                    activeTab == "3"
                      ? " main_card rates-step  justify-content-center align-items-center"
                      : "d-none"
                  }`}
                  id="step3"
                >
                  {/* <div>
								<Button
									// style={{ zIndex: 1, background: '#00b7ae' }}
									// color='secondary'
									onClick={() => {
										setActiveTab('2');
									}}
									className='step1-sub-left-btn ml-5'
								>
									<i className='fas fa-arrow-left text-white fa-1x' />
								</Button>
							</div> */}
                  <Rates
                    value={firstIntlPage}
                    secondIntlPage={secondIntlPage}
                    // query={queryData}
                    // onChange={handleThirdPage}

                    ratesData={ratesData}
                    onChange={ratesOnChange}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setThirdCompleted={setThirdCompleted}
                  />
                  {checkGirth() ||
                  (Object.keys(dhlRates) == 0 &&
                    Object.keys(fedexRates) == 0) ? (
                    <></>
                  ) : (
                    <Button
                      color="secondary"
                      className="step1-btn mr-md-5 mr-4"
                      onClick={() => {
                        // let obj = {
                        // 	rate: ratesData.rate,

                        // };
                        if (ratesData.rate == "") {
                          alert("Please select a service!");
                        } else {
                          delete ratesData.id;
                          localStorage.setItem(
                            "intl_third_page",
                            JSON.stringify(ratesData)
                          );

                          setActiveTab("4");
                          setThirdCompleted(true);
                        }
                      }}
                    >
                      <i className="fas fa-arrow-right " />
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    className="prev_btn ml-lg-5 ml-4"
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    <i className="fas fa-arrow-left " />
                  </Button>

                  {/* <Button
                color="secondary"
                onClick={() => {
                  setActiveTab("4");
                  const obj = Object.assign({}, ...thirdPage);
                  localStorage.setItem("intl_third_page", JSON.stringify(obj));
                }}
                className="step3-btn mr-5"
              >
                Next Step
              </Button> */}
                </div>
              </Col>

              <Col lg="12" align="center">
                <div
                  className={`${
                    activeTab == "4"
                      ? "d-block main_card  country-step "
                      : "d-none"
                  }`}
                >
                  <Calender
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    value={fourthPage}
                    onChange={handleFourthPage}
                  />
                  <div>
                    <Button
                      color="secondary"
                      onClick={() => {
                        const obj = Object.assign({}, ...fourthPage);
                        if (obj.date) {
                          localStorage.setItem(
                            "intl_fourth_page",
                            JSON.stringify(obj)
                          );
                          setActiveTab("5");
                        } else {
                          alert("please Add Date");
                        }
                      }}
                      className="step1-btn  mr-md-5 mr-4"
                    >
                      <i className="fas fa-arrow-right " />
                    </Button>
                    <Button
                      color="secondary"
                      className="prev_btn ml-lg-5 ml-4"
                      onClick={() => {
                        setActiveTab("3");
                      }}
                    >
                      <i className="fas fa-arrow-left " />
                    </Button>
                  </div>
                </div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const obj = Object.assign({}, ...fifthPage);
                    localStorage.setItem(
                      "intl_fifth_page",
                      JSON.stringify(obj)
                    );
                    setActiveTab("6");
                    // if (
                    // 	obj.sendingName &&
                    // 	obj.sendingSurName &&
                    // 	obj.sendingContact &&
                    // 	obj.sendingEmail &&
                    // 	obj.sendingCity &&
                    // 	obj.sendingAddress1 &&
                    // 	obj.ReceivingName &&
                    // 	obj.ReceivingSurName &&
                    // 	obj.ReceivingContact &&
                    // 	obj.ReceivingEmail &&
                    // 	obj.ReceivingCity &&
                    // 	obj.ReceivingAddress1 &&
                    // 	obj.idNumber
                    // ) {
                    // 	localStorage.setItem(
                    // 		'intl_fifth_page',
                    // 		JSON.stringify(obj)
                    // 	);
                    // 	setActiveTab('6');
                    // } else {
                    // 	alert('Please Fill All Details');
                    // }
                  }}
                >
                  <div
                    className={`${
                      activeTab == "5"
                        ? "d-block main_card country-step"
                        : "d-none"
                    }`}
                  >
                    {/* <div>
								<Button
									color='secondary'
									onClick={() => {
										setActiveTab('4');
									}}
									className='step1-sub-left-btn  mr-5'
								>
									<i className='fas fa-arrow-left text-white fa-1x' />
								</Button>
							</div> */}
                    <AddressDetail
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      value={fifthPage}
                      onChange={handleFifthPage}
                      first={firstIntlPage}
                    />
                    <div>
                      <Button
                        color="secondary"
                        type="submit"
                        className="step1-btn  mr-md-5 mr-4"
                      >
                        <i className="fas fa-arrow-right text-white fa-1x" />
                      </Button>
                      <Button
                        color="secondary"
                        className="prev_btn ml-lg-5 ml-4"
                        onClick={() => {
                          setActiveTab("4");
                        }}
                      >
                        <i className="fas fa-arrow-left " />
                      </Button>
                    </div>
                  </div>
                </Form>

                <div
                  className={`${
                    activeTab == "6"
                      ? "d-block main_card country-step"
                      : "d-none"
                  }`}
                >
                  {/* <div>
								<Button
									color='secondary'
									onClick={() => {
										setActiveTab('5');
									}}
									className='step1-sub-left-btn '
								>
									<i className='fas fa-arrow-left text-white fa-1x' />
								</Button>
							</div> */}
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();

                      if (
                        intlDeclaration.type == "commercial" ||
                        (declarationItems && reason && checkDeclarationItems())
                      ) {
                        if (
                          intlDeclaration.type == "commercial" &&
                          intlDeclaration.file == ""
                        ) {
                          alert("Please attach commercial invoice");
                        } else {
                          let totalShipmentValue = 0;
                          let itemDeclaration = declarationItems.map((decl) => {
                            let val =
                              Number(decl.quantity) * Number(decl.unitValue);
                            totalShipmentValue += val;
                            return {
                              ...decl,
                              totalValue: val.toFixed(2),
                            };
                          });

                          await localStorage.setItem(
                            "intl_sixth_page",
                            JSON.stringify({
                              declarationItems: itemDeclaration,
                              reason,
                              totalShipmentValue: totalShipmentValue.toFixed(2),
                            })
                          );

                          if (intlDeclaration.file != "") {
                            getBase64(intlDeclaration.file).then(
                              async (base64) => {
                                localStorage["fileBase64"] = base64;

                                await localStorage.setItem(
                                  "intl_declaration",
                                  base64
                                );
                              }
                            );
                          } else {
                            localStorage.removeItem("intl_declaration");
                          }

                          let totalAmount = 0;
                          let allAmounts = declarationItems.map((ti) => {
                            totalAmount = totalAmount + ti.totalValue;
                          });
                          let newAmount = 0;
                          if (thirdIntlPage.rate) {
                            newAmount = thirdIntlPage.rate;
                          }

                          setPaymentLoader(true);

                          // if (
                          // 	firstIntlPage.import ==
                          // 	true
                          // ) {
                          // 	ref = 'sms_imp_';
                          // } else {
                          // 	ref = 'sms_exp_';
                          // }
                          let ref = 1000;
                          if (
                            counters.length > 0 &&
                            counters[0].international_counter
                          ) {
                            ref = counters[0].international_counter + 1;
                          }

                          try {
                            let tempURL =
                              selectedCountry === "PT"
                                ? `${process.env.REACT_APP_DEPLOY}/international/thankyou-portugal`
                                : `${process.env.REACT_APP_DEPLOY}/international/confirmation`;
                            console.log(tempURL, "tempURL>>>>>>>>>>");
                            const response = await axios.post(
                              `${process.env.REACT_APP_DEVELOPMENT}/intlpayment`,
                              {
                                amount: Math.round(newAmount),
                                email: fifthIntlPage.sendingEmail,
                                reference: "sms-" + ref,
                                url: tempURL,
                              }
                            );

                            if (response?.data?.data) {
                              await setPaymentData(response?.data?.data);
                              setPaymentLoader(false);
                              await localStorage.setItem(
                                "intl_payment",
                                JSON.stringify(response.data.data)
                              );

                              setActiveTab("7");
                            } else {
                              console.log("Error", response);

                              setPaymentLoader(false);

                              alert("Kindly provide valid information");
                            }
                          } catch (error) {
                            console.log("Error", error);

                            setPaymentLoader(false);

                            alert("Kindly provide valid information");
                          }
                        }
                      } else {
                        alert("Please Select all fields");
                      }
                    }}
                  >
                    <ItemDeclaration
                      first={firstIntlPage}
                      second={secondIntlPage}
                      third={thirdIntlPage}
                      fourth={fourthIntlPage}
                      fifth={fifthIntlPage}
                      onChange={handleSixthPage}
                      query={queryData}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      declarationData={intlDeclaration}
                      declarationChange={declarationOnChange}
                      declarationItems={declarationItems}
                      declarationItemChange={declarationItemChange}
                      handleAddClickDeclaration={handleAddClickDeclaration}
                      handleRemoveClickDeclaration={
                        handleRemoveClickDeclaration
                      }
                      reason={reason}
                      setReason={setReason}
                    />
                    <div>
                      <Button
                        color="secondary"
                        type="submit"
                        className="step1-btn  mr-md-5 mr-5"
                      >
                        {paymentLoader ? (
                          <Spinner />
                        ) : (
                          <i className="fas fa-arrow-right text-white fa-1x" />
                        )}
                      </Button>
                      <Button
                        color="secondary"
                        className="prev_btn ml-lg-5 ml-5"
                        onClick={() => {
                          setActiveTab("5");
                        }}
                      >
                        <i className="fas fa-arrow-left " />
                      </Button>
                    </div>
                  </Form>
                </div>

                <div
                  className={`${
                    activeTab == "7"
                      ? "d-block main_card country-step"
                      : "d-none"
                  }`}
                >
                  {/* <div>
								<Button
									color='secondary'
									onClick={() => {
										setActiveTab('6');
									}}
									className='step1-sub-left-btn  mr-5'
								>
									<i className='fas fa-arrow-left text-white fa-1x' />
								</Button>
							</div> */}
                  <ConfirmBook
                    payment={paymentData}
                    value={seventhPage}
                    insuranceData={insuranceIntlData}
                    countryDetails={firstIntlPage}
                    itemDetails={secondIntlPage}
                    totalDetails={thirdIntlPage}
                    onChange={handleSeventhPage}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                  />
                  <div>
                    <Button
                      color="secondary"
                      className="prev_btn ml-5"
                      onClick={() => {
                        setActiveTab("6");
                      }}
                    >
                      <i className="fas fa-arrow-left " />
                    </Button>
                    {/* <Button
                  color="secondary"
                  onClick={() => {
                    const obj = Object.assign({}, ...seventhPage);
                    if (
                      obj.billingAddress1 &&
                      obj.billingAddress2 &&
                      obj.billingComplexNo &&
                      obj.billingSuburb &&
                      obj.billingCountry &&
                      obj.billingPostalCode
                    ) {
                      localStorage.setItem(
                        "intl_seventh_page",
                        JSON.stringify(obj)
                      );
                      setActiveTab("8");
                    } else {
                      alert("Please Fill All Details");
                    }
                  }}
                  className="step1-btn  mr-5"
                >
                  Next
                </Button> */}
                  </div>
                </div>

                <div
                  className={`${
                    activeTab == "8"
                      ? "d-block main_card country-step"
                      : "d-none"
                  }`}
                >
                  {/* <QuoteInvoice /> */}
                  {/* <QuotePaymentUI /> */}
                  <ItemInvoice
                    first={firstIntlPage}
                    second={secondIntlPage}
                    third={thirdIntlPage}
                    fourth={fourthIntlPage}
                    fifth={fifthIntlPage}
                    sixth={sixthIntlPage}
                    payment={paymentData}
                    seventh={seventhIntlPage}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    onChange={handleSixthPage}
                  />
                  {/* {activeTab == 8 &&
                window.open("/international/commercial", "_blank")} */}
                  {/* <CommercialInvoice
                first={firstIntlPage}
                second={secondIntlPage}
                third={thirdIntlPage}
                fourth={fourthIntlPage}
                fifth={fifthIntlPage}
                sixth={sixthIntlPage}
                payment={paymentData}
                seventh={seventhIntlPage}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onChange={handleSixthPage}
              /> */}
                  <div>
                    {/* <Button
                  color="secondary"
                  onClick={() => {
                    setActiveTab('9');
                    const obj = Object.assign({}, ...seventhPage);
                    localStorage.setItem(
                      'intl_seventh_page',
                      JSON.stringify(obj)
                    );
                  }}
                  className="step1-btn  mr-5"
                >
                  Submit
                </Button> */}
                  </div>
                </div>
                {/* <div className={`${activeTab == "9" ? "d-block " : "d-none"}`}>
                  <ItemPayementUi
                    first={firstIntlPage}
                    second={secondIntlPage}
                    third={thirdIntlPage}
                    fourth={fourthIntlPage}
                    fifth={fifthIntlPage}
                    sixth={sixthIntlPage}
                    seventh={seventhIntlPage}
                    eighth={eighthIntlPage}
                    commercial={commercialPage}
                    payment={payment}
                    reference={reference}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    insuranceData={insuranceIntlData}
                    onChange={handleSixthPage}
                    delcarationInvoice={getDeclarationFile()}
                  />
                  <div></div>
                </div> */}
                <div className={`${activeTab == "10" ? "d-block " : "d-none"}`}>
                  <IncompleteTransaction reference={reference} />
                  <div></div>
                </div>
              </Col>
              {/* <div
            className={`${activeTab == '4' ? 'd-block invoice-step' : 'd-none'
              }`}
            id="step4"
          >
            <Notes />
            <Button
              color="secondary"
              className="step3-btn mr-5"
              onClick={() => {
                setActiveTab('1');
              }}
            >
              Finish
            </Button>
          </div> */}
            </Row>
          </>
        )}
      </Container>
    </div>
  );
}

export default International;
